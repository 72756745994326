import React from 'react';
import zapier from '../../images/integrations/zapier.png';
import IntegrationBot from '../../components/integrations/indegrations';

const ZapierPage = () => (
  <IntegrationBot
    botLogo={zapier}
    botName={'Zapier'}
  />
);

export default ZapierPage;
